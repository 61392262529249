<template lang="pug">
  .register_wrapper
    .content_wrapper.row
      .form_block.column
        h2 Восстановление пароля
        form.login(@submit.prevent='reg')
          .astra_input_wrapper
            label.label_text(for='new_password') Телефон
            input.astra-input(v-model='mobile' type='text' placeholder='+7 (777) 000-00-00' v-mask="'+7 (7##) ###-##-##'")
          .notification_wrapper
            | Уже регистрировались? &nbsp;
            router-link(to='/public/auth') Авторизируйтесь
          .form_buttons_block
            button.astra_accept_button Продолжить
      .image_block.column
        img(src='~@/assets/astra-lock.png' alt='')
    .errors_wrapper(v-if='errors.length > 0')
      .error_item(v-for='error in errors' :key='error') {{error}}
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";

export default {
  components: {},
  name: "register",
  data() {
    return {
      mobile: ""
    };
  },
  computed: {
    errors() {
      return this.$store.state.registration.errors;
    }
  },
  directives: { mask },
  validations: {
    mobile: { required, minlength: minLength(13) },
  },
  methods: {
    reg: function() {
      let { dispatch } = this.$store;
      let raw_phone = this.mobile
        .replace(/\s/g, "")
        .replace(/-/g, "")
        .replace(/\)/g, "")
        .replace(/\(/g, "");

      let mobile = raw_phone;

      this.$v.$touch();
      if (this.$v.$invalid) return;
      dispatch("registration/RecoveryPaswwordSendSMS", mobile);
    }
  }
};
</script>

<style >
@import url("~@/assets/scss/steps.scss");
</style>