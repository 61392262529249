<template>
  <header class="header_wrapper">

    <!-- <a href="#" class="astra_burger" v-on:click="menuon">
      <img src="~@/assets/astra-burger.png" alt />
    </a> -->

    <router-link to="/" class="astra_logo">
      <img src="~@/assets/astra-logo.png" alt />
    </router-link>

    <!-- <div class="header_right_block">
      <router-link to="/public/reg/step1" class="logout">
        <span class="logout_text">Регистрация</span>
        <img class="logout_icon" src="~@/assets/astra-man.png" alt="Выход" />
      </router-link>
    </div> -->

  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {
    menuon() {
      this.$store.commit("menuon");
    },
  },
};
</script>

<style scoped>
.header_wrapper {
  background-color: transparent;
  width: 100%;
  height: 100px;
  padding: 55px 39.26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_wrapper .header_right_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_wrapper .logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 84px; */
}

.header_wrapper .logout span {
  margin-right: 25px;
}

.header_wrapper .change_language {
  font-family: "Segoe Ui", Helvetica, Arial, sans-serif;
  color: #ef7f19;
  font-size: 16px;
  font-weight: bold;
}

.astra_burger,
.astra_man {
  display: none;
}

@media only screen and (max-width: 700px) {
  .header_wrapper {
    width: 100%;
    height: inherit;
    padding: 20px;
  }

  .astra_burger,
  .astra_man {
    display: inline-block;
  }

  .header_wrapper .header_right_block {
    display: none;
  }

  .header_wrapper .astra_logo img {
    width: 145px;
  }
}
</style>
