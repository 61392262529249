<template>
  <div class="public_wrapper">
    <Header></Header>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  components: {
    Header
  },
  name: "public"
};
</script>

<style >
.public_wrapper .change_password_wrapper {
  max-width: 880px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px 50px;
}
</style>