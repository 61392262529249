import { userService } from '../../_services';
import { router } from '../../router';
// import Vue from "vue";


const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

export const authentication = {
    namespaced: true,
    state: { ...initialState, errors: [] },
    actions: {
        login({  commit, rootState, state }, { username, password }) {
            commit('loginRequest', { username });
            rootState.fetching = true;

            userService.login(username, password)
                .then(
                    token => {
                        rootState.fetching = false;

                        if (token) {
                            localStorage.setItem('user_token', token.data.token);
                        }
                        commit('loginSuccess', user);
                        router.push("/personal-cabinet/my-loans");
                    },
                    error => {
                        rootState.fetching = false;
                        // console.group('error')
                        //     console.log('state', state)
                        //     console.log('error', error)
                        //     console.log('error')
                        // console.groupEnd()

                        state.errors = [ error ]
                    }
                );

        },
        logout({ commit }) {
            userService.logout();
            router.push('/');
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
            state.errors.push('Error')
        },
        logout(state) {
            state.status = {};
            state.user = null;
        }
    }
}