import Vue from "vue";
import VueRouter from "vue-router";

const MyLoans = () => import(/* webpackChunkName: "profile" */ './components/PersonalCabinet/MyLoans.vue')
const MyData = () => import(/* webpackChunkName: "profile" */ './components/PersonalCabinet/MyData.vue')
const TransacHistory = () => import(/* webpackChunkName: "profile" */ './components/PersonalCabinet/TransacHistory.vue')
const LoanDetails = () => import(/* webpackChunkName: "profile" */ './components/PersonalCabinet/LoanDetails.vue')
const PersonalCabinet = () => import(/* webpackChunkName: "profile" */ './components/PersonalCabinet/PersonalCabinet.vue')
import ChangePassword from "./components/PersonalCabinet/ChangePassword.vue";
import Public from "./components/Public/Public.vue";

import Register from "./components/Register/Register.vue";
import StepTest from "./components/Register/StepTest.vue";
import Step1 from "./components/Register/Step1.vue";
import Step2 from "./components/Register/Step2.vue";
import Auth from "./components/Register/Auth.vue";

import PasswordRecovery from "./components/PasswordRecovery/PasswordRecovery.vue";
import PasswordRecovery_Step1 from "./components/PasswordRecovery/SendSMS.vue";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "",
      redirect: '/public/auth'
    },
    {
      path: "/personal-cabinet",
      component: PersonalCabinet,
      children: [
        {
          path: "my-loans",
          component: MyLoans
        },
        {
          path: "change-password",
          component: ChangePassword
        },
        {
          path: "my-data",
          component: MyData
        },
        {
          path: "transac-history",
          component: TransacHistory
        },

        { path: 'LoanDetails:key', name: 'LoanDetails', component: LoanDetails },

        {
          path: '/', component: MyLoans
        },

        {
          path: '', component: MyLoans
        }
      ]
    },
    {
      path: '/register',
      component: Step1
    },
    {
      path: '/public',
      component: Public,
      children: [
        {
          path: "reg",
          component: Register,
          children: [
            {
              path: 'steptest',
              component: StepTest,
            },
            {
              path: 'step1',
              component: Step1,
            },
            {
              path: 'step2',
              component: Step2,
            },
            {
              path: 'step3/:title',
              component: ChangePassword,
              props: true
            }
          ],
        },
        {
          path: "password-recovery",
          component: PasswordRecovery,
          children: [
            {
              path: 'step1',
              component: PasswordRecovery_Step1,
            },
            {
              path: 'step2',
              component: Step2,
            },
            {
              path: 'step3/:title',
              component: ChangePassword,
              props: true
            }
          ],
        },

        {
          path: 'auth',
          component: Auth
        }
      ]
    }
  ]
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/public', ];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user-token');

//   if (authRequired && !loggedIn) {
//     // return next('/public/reg/step1');
//   }

//   next();
// })
