<template>
  <div style="background-color: transparent; width: 400px; margin: auto;">
    <form class="login" @submit.prevent="reg">
      <h1>Регистрация</h1>

      <div class="astra_input_wrapper">
        <label class="label_text">ИИН</label>
        <input class="astra-input" required v-model="reg_form.uin" type="text" placeholder="ИИН" />
      </div>
      <div class="astra_input_wrapper">
        <label class="label_text">Телефон</label>
        <input class="astra-input" required v-model="reg_form.mobile" type placeholder="Телефон" />
      </div>
      <hr />
      <button class="astra_button" type="submit">Далее</button>
    </form>
    <div>
      <button @click="sendSMS">Выслать СМС</button>
    </div>

    <div>
      <input class="astra-input" required v-model="otp_code" type placeholder="OTP" />
      <button @click="sendOTP">Отправить ОТП</button>
    </div>

    <div>
      <input class="astra-input" required v-model="pass.new_password1" type placeholder="pass1" />
      <input class="astra-input" required v-model="pass.new_password2" type placeholder="pass2" />
      <button @click="changePASS">Установить пароль</button>
    </div>

    <div>
      <input class="astra-input" required v-model="auth.username" type placeholder="pass1" />
      <input class="astra-input" required v-model="auth.password" type placeholder="pass2" />
      <button @click="authme">Авторизоваться</button>
    </div>

    <div>
      <button @click="tickets()">tickets</button>
    </div>

    <div>
      <button @click="logout">Выйти</button>
    </div>
  </div>
</template>

<script>
// import { AUTH_REQUEST } from "../store/actions/auth";
import axios from "axios";

export default {
  components: {
  },
  name: "login",
  data() {
    return {
      reg_form: {
        mobile: "+77776172771",
        uin: "900529351495"
      },
      otp_code: "",
      pass: {
        new_password1: "",
        new_password2: ""
      },
      auth: {
        username: "+77776172771",
        email: "",
        password: ""
      }
    };
  },
  methods: {
    reg: function() {
      //   const { username, password } = this;
      //   this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
      //     this.$router.push("/personal-cabinet/");
      //   });

      axios
        .post(
          `http://astra-api.thefactory.kz/api/v1/account/register/`,
          this.reg_form,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          console.log("astra say hello ", response);
          this.sendSMS();
        })
        .catch(e => {
          console.log("astra say goodbye ", e, e.response);
          this.errors.push(e);
        });
    },
    sendSMS: function() {
      axios
        .post(
          `http://astra-api.thefactory.kz/api/v1/auth/login/mobile/`,
          { mobile: this.reg_form.mobile },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          console.log("sendSMS ", response);
        })
        .catch(e => {
          console.log("astra say goodbye ", e, e.response);
          this.errors.push(e);
        });
    },
    sendOTP: function() {
      axios
        .post(
          `http://astra-api.thefactory.kz/api/v1/auth/token/`,
          { mobile: this.reg_form.mobile, token: this.otp_code },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          console.log("sendOTP ", response);
          localStorage.setItem("user-token", response.data.token);
        })
        .catch(e => {
          console.log("sendOTP ", e, e.response);
          this.errors.push(e);
        });
    },
    changePASS: function() {
      axios
        .post(
          `http://astra-api.thefactory.kz/api/v1/account/password/change/`,
          this.pass,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${localStorage.getItem("user-token")}`
            }
          }
        )
        .then(response => {
          console.log("astra say hello ", response);
        })
        .catch(e => {
          console.log("astra say goodbye ", e, e.response);
          this.errors.push(e);
        });
    },
    authme: function() {
      axios
        .post(`http://astra-api.thefactory.kz/api/v1/auth/login/`, this.auth, {
          headers: {
            "Content-Type": "application/json"
            //   Authorization: `Bearer ${localStorage.getItem("user-token")}`
          }
        })
        .then(response => {
          console.log("astra say hello ", response);
          localStorage.setItem("user-token", response.data.key);
        })
        .catch(e => {
          console.log("astra say goodbye ", e, e.response);
          this.errors.push(e);
        });
    },
    logout() {
      axios
        .post(`http://astra-api.thefactory.kz/api/v1/auth/logout/`, this.auth, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("user-token")}`
          }
        })
        .then(response => {
          console.log("astra say hello ", response);
        })
        .catch(e => {
          console.log("astra say goodbye ", e, e.response);
          this.errors.push(e);
        });
    },
    tickets() {
      axios
        .get(`http://astra-api.thefactory.kz/api/v1/tickets/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("user-token")}`
          }
        })
        .then(response => {
          console.log("astra say hello ", response);
        })
        .catch(e => {
          console.log("astra say goodbye ", e, e.response);
          this.errors.push(e);
        });
    }
  }
};
</script>

<style scoped>
</style>