<template>
  <div class="loading_spinner_wrapper">
    <div class="loading_spinner_inner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
$background_color: rgba(0, 0, 0, 0.92);
$spin_color: #ff6a00;

@keyframes loading_spinner_inner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading_spinner_inner div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: loading_spinner_inner linear 1s infinite;
  background: $spin_color;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}
.loading_spinner_inner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: $spin_color;
}
.loading_spinner_inner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: $spin_color;
}
.loading_spinner_wrapper {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: $background_color;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading_spinner_inner {
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  width: 200px;
  height: 200px;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loading_spinner_inner div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>
