import { authHeader } from '../_helpers'
import { store } from '@/store'

export const loanService = { getAll, getByKey, getPayInfo, getAllTransactions, getClosed }

function getAll() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({closed: false})
    };

    return fetch(`${process.env.VUE_APP_API}/v${process.env.VUE_APP_API_VERSION}/loans`, requestOptions)
    .then(handleResponse);
}

function getClosed() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({closed: true})
    };

    return fetch(`${process.env.VUE_APP_API}/v${process.env.VUE_APP_API_VERSION}/loans`, requestOptions)
    .then(handleResponse);
}
async function getByKey(key) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json; charset=utf-8', ...authHeader() },
    };

    return fetch(`${process.env.VUE_APP_API}/v${process.env.VUE_APP_API_VERSION}/loans/${key}/`, requestOptions).then(handleResponse);
}

function getPayInfo(key) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ numbers: [key] }),
        headers: { 'Content-Type': 'application/json; charset=utf-8', ...authHeader() },
    };


    return fetch(`${process.env.VUE_APP_API}/v${process.env.VUE_APP_API_VERSION}/loans/pay`, requestOptions).then(handleResponse);
}

function getAllTransactions() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ pageNumber: 1, pageSize: 100 })
    };

    return fetch(`${process.env.VUE_APP_API}/v${process.env.VUE_APP_API_VERSION}/transactions/list`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;

            switch (response.status) {
                case 555: {
                    console.log(response);
                    console.log('555 error', error);
                    store.commit('notes_module/showNote', {"name":'1c_error', "message":error});
                }
            }

            return Promise.reject(error);
        }
        return data.data;
    });
}