<template>
  <transition name="modal">
    <div v-if="note" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header"></div>

          <div class="modal-body">
            <div class="modal-body__title">{{note.modal.title}}</div>
            <div class="modal-body__descriptions" v-if="note.modal.descriptions">
              <div
                class="modal-body__description"
                v-for="(item, index) in note.modal.descriptions"
                :key="index + 'desc'"
              >{{item}}</div>
            </div>
            <div class="modal-body__note">{{note.modal.note}}</div>
          </div>

          <div class="modal-footer">
            <div class="nrfooter">
              <button class="astra_accept_button" @click="closeModal">
                <div class="nr_bomba_want_btn">{{note.modal.btn_text}}</div>
              </button>
            </div>
          </div>

          <div class="modal_close" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    note() {
      return this.$store.getters["notes_module/NOTE"];
    },
  },
  methods: {
    closeModal() {
      console.log("closing module");
      this.$store.commit("notes_module/closeNote", this.note.name);
    },
  },
};
</script>

<style lang="scss">
.modal-footer {
  .nrfooter {
    .nar_close_btn {
      background-color: #239bd5;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
      color: #fff;
      border-radius: 30px;
      cursor: pointer;
      width: 200px;
      height: 40px;
      font-size: 14px;
      margin: 0 auto;
      font-weight: 600;
      line-height: 40px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }
  }
}

.modal-body__title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #343a8b;
}

.modal-body__descriptions {
  margin: 2rem 0;
}

.modal-body__description {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #707070;
  margin: 0.5rem 0;
}

.modal-body__note {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #707070;

  margin: 0.5rem 0;
}

.modal_close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.modal_close:hover {
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  width: 537px;
  margin: 0px auto;
  padding: 40px 90px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header .header {
  margin-top: 0;
  color: #333;
  font-size: 2rem;
  font-weight: bold;
}

.modal-body {
  margin: 40px 0;
  line-height: 1.8rem;
  font-size: 1.1rem;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (max-width: 767.98px) {
  .modal-container {
    width: 90%;
    padding: 40px 20px;
  }

  .modal-header .header {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .modal-body {
    line-height: 2.4rem;
    font-size: 1.4rem;
  }

  .modal-body__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .modal-container {
    width: 90%;
  }

  .modal-header .header {
    font-size: 24px;
    line-height: 30px;
  }

  .modal-body {
    line-height: 30px;
    font-size: 20px;
  }
}
</style>