import { authHeader } from '../_helpers';
import { store } from '@/store'
import {router} from '@/router'

export const userService = {
    login,
    logout,
    getAll,
    getUser,
    changePassword,
    changeUser,
    registrationStep1,
    sendSMS,
    registrationStep2

};

function login(username, password) {
    var body = {
         phoneNumber: username,
         password: password
         }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.VUE_APP_API}/tokens/`, requestOptions)
        .then(handleResponse)
        .then(data => data);
}

function logout() {
    router.push('/')
    localStorage.removeItem('user_token');
    localStorage.removeItem('refresh_token');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API}/users`, requestOptions).then(handleResponse);
}

function getUser() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API}/profile/`, requestOptions).then(handleResponse);
}

function changePassword({ new_password1, new_password2 }) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({ new_password1, new_password2 })
    };

    return fetch(`${process.env.VUE_APP_API}/auth/password/change/`, requestOptions).then(handleResponse);
}


function changeUser(profile) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(profile)
    };

    return fetch(`${process.env.VUE_APP_API}/auth/user/`, requestOptions).then(handleResponse);
}


function registrationStep1(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.VUE_APP_API}/identity/register`, requestOptions).then(handleResponse);
}

function registrationStep2(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.VUE_APP_API}/auth/token/`, requestOptions).then(handleResponse);
}


function sendSMS(mobile) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber: mobile })
    };

    return fetch(`${process.env.VUE_APP_API}/identity/forgot-password/`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            let error = data.exception || data.title;

            switch (response.status) {
                case 401: {
                    console.log('401')
                    logout();
                    // location.reload(true);
                    break;
                }
                case 403: {
                    error = 'Для продолжения работы вам необходимо стать клиентом Астра-Ломбард'
                    break;
                }
                case 523: {
                    console.log(response);
                    store.commit('notes_module/showNote', {"name":'1c_error', "message":error});
                }
            }
            console.log(Promise.reject(error));
            return Promise.reject(error);
        }
        return data;
    });
}