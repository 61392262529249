<template lang="pug">
  .register_wrapper
    .content_wrapper.row
      .form_block.column
        h2.nar_title Регистрация
        form.login(@submit.prevent='reg')
          .astra_input_wrapper
            label.label_text(for='new_password') Телефон
            input.astra-input(v-model='phoneNumber' type='text' placeholder='+7 (777) 000-00-00' v-mask="'+7 (7##) ###-##-##'")
          .astra_input_wrapper
            label.label_text(for='confirm_password') ИИН
            input.astra-input(v-model='individualIdentificationNumber' type='text' placeholder='Введите ИИН' v-mask="'############'")
          .notification_wrapper
            | Уже регистрировались? &nbsp;
            router-link(to='/public/auth') Авторизируйтесь
          .form_buttons_block
            button.astra_accept_button Продолжить
      .image_block.column
        img(src='~@/assets/astra-lock.png' alt='')
    .errors_wrapper(v-if='errors.length > 0')
      .error_item(v-for='error in errors' :key='error') {{error}}
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";

export default {
  components: {},
  name: "register",
  data() {
    return {
      individualIdentificationNumber: "",
      phoneNumber: "",
    };
  },
  computed: {
    errors() {
      return this.$store.state.registration.errors;
    }
  },
  directives: { mask },
  validations: {
    phoneNumber: { required, minlength: minLength(13) },
    individualIdentificationNumber: { required, minLength: minLength(12) }
  },
  methods: {
    reg: function() {
      let { dispatch } = this.$store;
      let raw_phone = this.phoneNumber
        .replace(/\s/g, "")
        .replace(/-/g, "")
        .replace(/\)/g, "")
        .replace(/\(/g, "");

      let { individualIdentificationNumber, phoneNumber } = 
      { 
        individualIdentificationNumber: this.individualIdentificationNumber,
        phoneNumber: raw_phone 
      };

      console.log("reg => ", { individualIdentificationNumber, phoneNumber }, raw_phone);

      this.$v.$touch();
      console.log(this.$v.$invalid);
      if (this.$v.$invalid) return;
      dispatch("registration/registrationStep1", { individualIdentificationNumber, phoneNumber });
    }
  }
};
</script>

<style >
@import url("~@/assets/scss/steps.scss");
</style>