import { loanService } from '../../_services';
// import Vue from 'vue'

export const notes_module = {
    namespaced: true,
    state: {
        notes: [
            {
                code: 1,
                name: 'pay_time_error',
                description: `Оплачивать залоговый билет можно с 08:00 до 23:00. В остальное время с сервера будет приходить   Код ошибки: {"error": "Time error"}, status=406`,
                show: false,
                modal: {
                    title: 'Оплата невозможна',
                    descriptions: ['В данное время вы не можете провести продление залогового билета'],
                    note: 'Оплаты принимаются с 08:00 до 23:00',
                    btn_text: 'Закрыть'
                }
            },
            {
                code: 2,
                name: '1c_error',
                description: `Возможны ситуации, когда 1С астры будет недоступна по техническим причинам. В это время мы показываем пользователю сообщение.`,
                show: false,
                modal: {
                    title: 'Сервисы недоступны',
                    descriptions: ['В данный момент наши сервисы недоступны. Попробуйте позднее.'],
                    btn_text: 'Закрыть'
                }
            }
        ]
    },
    actions: {
        getAllTransactions({ commit }) {
            loanService.getAllTransactions().then(
                data => commit('getAllTransactionsSuccess', data),
                error => commit('getAllTransactionsFailure', error)
            )
        }
    },
    mutations: {
        closeNote(state, note_name) {
            let note = state.notes.find(a => a.name === note_name);
            note.show = false;
        },
        showNote(state, args) {
            let note = state.notes.find(a => a.name === args.name);
            console.log('message', args.message);
            if (args.message.length > 0 && args.message != "Unknown Status Code") {
                note.modal.title = "Уведомление";
                note.modal.descriptions = [args.message];
            }
            console.log('note', note);
            note.show = true;
        }
    },
    getters: {
        NOTE: state => state.notes.find(a => a.show)
    }
}