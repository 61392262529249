import { loanService } from '../../_services';
import Vue from 'vue'

export const loans_module = {
    namespaced: true,
    state: {
        all: {},
        loan: null,
        pay_info: null,
        transactions: null,
        is_server_error: false,
    },
    actions: {
        getAll({ commit }) {
            commit('setServerError', false);
            loanService.getAll().then(
                data => commit('getAllSuccess', data),
                error => commit('getAllFailure', error)
            )
        },
        getClosed({ commit }) {
            commit('setServerError', false);
            loanService.getClosed().then(
                data => commit('getClosedSuccess', data),
                error => commit('getClosedFailure', error)
            )
        },
        async getByKey({ commit }, key) {
            commit('setServerError', false);
            await loanService.getByKey(key).then(
                data => commit('getByKeySuccess', data),
                error => commit('getByKeyFailure', error)
            )
        },
        async getPayInfo({ commit }, key) {
            commit('setServerError', false);
            loanService.getPayInfo(key).then(
                data => commit('getPayInfoSuccess', data),
                error => commit('getPayInfoFailure', error)
            )
        },
        getAllTransactions({ commit }) {
            commit('setServerError', false);
            loanService.getAllTransactions().then(
                data => commit('getAllTransactionsSuccess', data),
                error => commit('getAllTransactionsFailure', error)
            )
        },
    },
    mutations: {
        setServerError (state, result) {
            state.is_server_error = result;
        },
        getAllSuccess(state, loans) {
            let day = 86400000;
            let norm_loans = loans.map(item => {
                item.return_loan_days = Math.round((new Date(item.return_date).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) / day)
                if (item.closing_date)
                    item.closing_loan_days = Math.round((new Date(item.closing_date).setHours(0,0,0,0) - new Date(item.loan_date).setHours(0,0,0,0)) / day)
                else
                    item.closing_loan_days = null
                return item;
            })


            state.all = { items: norm_loans };
        },
        getClosedSuccess(state, loans) {
            let day = 86400000;
            let norm_loans = loans.map(item => {
                item.return_loan_days = Math.round((new Date(item.return_date).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) / day)
                if (item.closing_date)
                    item.closing_loan_days = Math.round((new Date(item.closing_date).setHours(0,0,0,0) - new Date(item.loan_date).setHours(0,0,0,0)) / day)
                else
                    item.closing_loan_days = null
                return item;
            })

            state.closed = {items: norm_loans };
        },
        getAllFailure(state, error) {

            state.all = { error }
        },

        getClosedFailure(state, error) {

            state.closed = { error }
        },

        getByKeySuccess(state, loan) {
            let day = 86400000;

            loan.return_loan_days = Math.round((new Date(loan.return_date).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) / day)
            if (loan.closing_date)
                loan.closing_loan_days = Math.round((new Date(loan.closing_date).setHours(0,0,0,0) - new Date(loan.loan_date).setHours(0,0,0,0)) / day)
            else
                loan.closing_loan_days = null
            state.loan = loan;


            console.log('loan category => ', loan.category) 
            // if(loan.category.trim().toLowerCase() === 'золото'){
                
            // }
        },
        getByKeyFailure(state, error) {
            state.all = { error }
        },

        getPayInfoSuccess(state, info) {
            console.log(info);
            Vue.set(state, 'pay_info', info);
            console.log("GET PAY INFO SUCCESS", info);
            Vue.set(state, 'is_server_error', false);
            // state.pay_info = info;
        },

        getPayInfoFailure(state, error) {
            console.log('pay_info error', error);
            Vue.set(state, 'is_server_error', true);

            // state.pay_info = info;
        },

        getAllTransactionsSuccess(state, transactions) {
            console.log('commit ', transactions)
            Vue.set(state, 'transactions', transactions)

        },
        getAllTransactionsFailure(state, error) {
            state.transactions = { error }
        },


    },
    getters: {
        LOANS: state => {
            return state.all.items
        },
        ACTIVE_LOANS: state => {
            if (!state.all.items)
                return null
            return state.all.items || null
        },
        PASSIVE_LOANS: state => {
            return state.closed.items || null
        },
        TRANSACTION_LIST_BY_LOANID: state => id => {
            return state.all.items.find(x => x.id === id).transactions
        },
        LOAN_BY_ID: state => id => {
            return state.all.items.find(x => x.uuid === id)
        },
        LOAN_BY_KEY(state) {
            return key => {
                return state.all.items.find(x => x.key === key)
            }
        },
        LOAN: state => state.loan,
        PAY_INFO: state => state.pay_info,
        IS_SERVER_ERROR: state => state.is_server_error,
        TRANSACTIONS: state => state.transactions,

    }
}