import Vuex from 'vuex'
import Vue from 'vue'
import { authentication } from './modules/authentication.module';
import { registration } from './modules/registration.module';
import { users } from './modules/users.module';
import { loans_module } from './modules/loans.module'
import { notes_module } from './modules/notes.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
      authentication, users, loans_module, registration, notes_module
  },

  state: {
    isMobileMenu: false,
    fetching: false,
    payError: '',
    show_modal: true
  },

  getters: {
    USERNAME: state => {
      return state.username
    },

    IS_MOBILE_MENU: state => {
      return state.isMobileMenu
    }
  },

  mutations: {
    menuoff(state) {
      state.isMobileMenu = false;
    },
    menuon(state) {
      state.isMobileMenu = true;
    },
    toggle(state) {
      state.isMobileMenu = !state.isMobileMenu
    }
  },
});