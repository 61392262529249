import { userService } from '../../_services';
import { router } from '../../router'
import Vue from 'vue'

export const users = {
    namespaced: true,
    state: {
        all: {},
        profile: {}
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            userService.getAll()
                .then(
                    users => commit('getAllSuccess', users),
                    error => commit('getAllFailure', error)
                );
        },
        getProfile({ commit }) {
            commit('getProfileRequest');

            userService.getUser()
                .then(
                    user => commit('getProfileSuccess', user),
                    error => commit('getProfileFailure', error)
                );
        },
        changePassword({ commit, rootState }, { new_password1, new_password2 }) {
            rootState.fetching = false;

            commit('getProfileRequest');

            userService.changePassword({ new_password1, new_password2 })
                .then(
                    user => {
                        rootState.fetching = false;

                        commit('getProfileSuccess', user);
                        router.push('/personal-cabinet/my-loans/')

                    },
                    error => {
                        rootState.fetching = false;
                        commit('getProfileFailure', error)
                    }
                );
        },

        changeUser( profile) {
            userService.changeUser(profile).then(
                data => {
                    console.log('module - changUser - repsonse', data)
                    return
                }
            )
        },
    },
    mutations: {
        getAllRequest(state) {
            state.profile = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        },


        getProfileRequest(state) {
            state.profile = { loading: true };
        },
        getProfileSuccess(state, profile) {
            Vue.set(state, 'profile', profile)
        },
        getProfileFailure(state, error) {
            state.profile = { error };
        },
    }
}