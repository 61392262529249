<template lang="pug">
  .register_wrapper
    .content_wrapper.row
      .form_block.column
        h2.nar_title Авторизация
        form.login(@submit.prevent='handleSubmit')
          .astra_input_wrapper(:class="{form_group_error: ($v.username.$dirty && !$v.username.minLength) || ($v.username.$dirty && !$v.username.required) }")
            label.label_text(for='username') Логин
            input.astra-input(v-model='$v.username.$model' required type='text' name="username"  placeholder='+7 (777) 000-00-00' v-mask="'+7 (7##) ###-##-##'")
            .error(v-if="$v.username.$dirty && !$v.username.required") Необходимо заполнить это поле
            .error(v-if="$v.username.$dirty && !$v.username.minLength") Введите корректный номер
          .astra_input_wrapper
            label.label_text(for='password') Пароль
            input.astra-input(v-model='$v.password.$model' required type='password' placeholder='Введите пароль')
            .error(v-if="$v.password.$dirty && !$v.password.required") Необходимо заполнить это поле
            .error(v-if="$v.password.$dirty && !$v.password.minLength") Менее 3 символов
          
          .notification_wrapper  
            | Нет аккаунта? &nbsp;
            router-link(to='/public/reg/step1') Зарегистрируйтесь <br/><br/>
            //- router-link(to='/public/password-recovery/step1') Забыли пароль?
          .form_buttons_block
            button.astra_accept_button Продолжить
      .image_block.column
        img(src='~@/assets/astra-lock.png' alt='')
    .errors_wrapper(v-if='errors.length > 0')
      .error_item(v-for='error in errors' :key='error') {{error}}
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";

export default {
  components: {},
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      email: ""
    };
  },

  computed: {
    errors() {
      return this.$store.state.authentication.errors;
    }
  },
  directives: { mask },
  validations: {
    username: { required, minLength: minLength(18) },
    password: { required, minLength: minLength(3) }
  },
  methods: {
    handleSubmit: function() {

      if (this.$v.$invalid) return;
      console.log('its ok')

      let { dispatch } = this.$store;
      let raw_phone = this.username
        .replace(/\s/g, "")
        .replace(/-/g, "")
        .replace(/\)/g, "")
        .replace(/\(/g, "");
      let { username, password } = {
        username: raw_phone,
        password: this.password
      };

      dispatch("authentication/login", { username, password });
    }
  }
};
</script>

<style>
@import url("~@/assets/scss/steps.scss");

.error {
  padding-top: 8px;
  color: #ff7d7d;
}
</style>